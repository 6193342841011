<template>
	<div class="pay-confirm-container"
		:style="`background: url(${bgSrc}) no-repeat;background-size:100% 45%;padding-top:50px`">
		<!--背景图-->
		<div class="card">
			<div class="header">
				<img style="width: 50px;height: 50px" :src="addOilSrc" alt="">
				<span class="add-oil-station">{{ address }}</span>
				<strong class="oil-number">{{ youhao }}</strong>
			</div>
			<div class="content">
				<ul>
					<li>
						<span>挂牌价</span>
						<span>￥{{ price }}/{{unit}}</span>
					</li>
					<li v-if="isShowImg">
						<span>优惠</span>
						<span>-￥{{ discount }}/{{unit}}</span>
					</li>
					<li v-if="isShowImg">
						<span>数量</span>
						<span>{{ rise }}{{unit}}</span>
					</li>
					<li>
						<span>金额</span>
						<span>￥{{ money }}</span>
					</li>
				</ul>
			</div>
		</div>
		<!--      金额优惠-->
		<div class="pay-discount">
			<div class="pay-content">
				<img style="width: 15px;height: 15px;padding-right: 10px"
             :src="isShowImg?discountIcon:qhdBankIcon" alt="" srcset=""  >
				<span v-if="isShowImg">金额优惠</span>
				<span v-if="!isShowImg" >秦皇岛银行代付</span>
				<span class="discount-money" v-if="isShowImg" >-￥{{ discountMoney }}</span>
				<span class="discount-money" v-if="!isShowImg" >￥{{ discountMoney }}</span>
			</div>
			<div class="real-pay">
				<span>实付</span>
				<span>￥{{ finalMoney }}</span>
			</div>
		</div>
		<div class="bottom-img" style="width: 95%" v-if="!isShowImg">
			<img :src="qhdBank" alt="" srcset="" style="width: 100%">
		</div>
		<div class="bottom-btn-box">
			<van-button type="primary" block @click="submitData">确认支付</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "payConfirm",
		data() {
			return {
				isShowImg: true,
				unit: "L",
				qhdBankIcon:require("@/assets/img/qhdBankIcon.png"),
				qhdBank: require("@/assets/img/addCNG.png"),
				bgSrc: require("@/assets/img/4.png"),
				iconSrc: require("@/assets/img/8.png"),
				addOilSrc: require("@/assets/img/3.png"),
				discountIcon: require("@/assets/img/5.png"),
				orderId: "",
				address: "", //
				youhao: "",
				discountMoney: "", //优惠金额
				finalMoney: "", //实付款
				money: "",
				rise: "",
				discount: "", //优惠价
				price: "", //挂牌价
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			async getData() {
				this.orderId = this.$route.query.orderId; //获取加油付款页面生成的orderid
				const res = await this.$get("/jsapi/get_order_info", {
					orderId: this.orderId
				})
				if (res.code === 200) {
					const data = res.data;
					this.address = data.title;
					this.youhao = data.youhao;
					this.discountMoney = data.discountMoney;
					this.finalMoney = data.finalMoney;
					this.rise = data.rise;
					this.discount = data.discount;
					this.price = data.price;
					this.money = data.money.toString().includes(".") ? data.money : data.money+".00";
          window.console.log(this.money);
					this.unit = this.youhao === "CNG" ? "m³" : "L";
					if (data.youhao === 'CNG') {
						this.isShowImg=false;
					}
				}
			},
			submitData() {
				this.$router.push({
					name: "showPayPage",
					path: "showPayPage",
					query: {
						orderId: this.orderId, //传给支付成功页面
					}
				})

			}
		}
	}
</script>

<style scoped lang="less">
	.pay-confirm-container {
		.card {
			width: 95%;
			margin: 0 auto;
			background: #fff;
			border-radius: 6px;
			box-shadow: 0 0 10px -6px #000;

			.header {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 10px 20px;

				.add-oil-station {
					font-size: 15px;
					padding-left: 10px;
					font-weight: 700;
				}

				.oil-number {
					flex: 1;
					font-size: 26px;
					text-align: right;
					color: #2380ff;
				}
			}

			.content {
				padding: 0 20px 5px 35px;

				li {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 5px 0;

					span {
						font-size: 14px;
					}
				}

				li:nth-child(2) {
					color: red;
				}
			}
		}

		.pay-discount {
			background: #fff;
			margin: 15px auto 0 auto;
			width: 95%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			border-radius: 6px;
			box-shadow: 0 0 10px -6px #000;

			.pay-content {
				width: 93%;
				padding: 10px 20px 10px 0;
				border-bottom: 1px solid #eee;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				span {
					font-size: 14px;
					height: 15px;
					line-height: 15px;
					display: inline-block;
				}

				.discount-money {
					flex: 1;
					text-align: right;
					color: red;
				}
			}

			.real-pay {
				margin: 10px 0;
				padding-right: 20px;

				span:first-child {
					font-size: 15px;
				}

				span:last-child {
					font-size: 18px;
					padding-left: 5px;
					color: red;
					font-weight: 600;
				}
			}
		}
	}
  .bottom-img{
    width: 95%;
    margin: 30px auto 0 auto;
  }
	.bottom-btn-box {
		width: 95%;
		margin: 0 auto;
		padding-top: 40px;
		box-sizing: border-box;

		/deep/ .van-button--block {
			border-radius: 20px;
			background: #2380ff;
		}

		/deep/ .van-button--primary {
			border: #2380ff;
		}
	}
</style>
